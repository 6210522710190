.one1 {
  margin-top: 40px;
}

.trns {
  transform: translateX(140px);
}


@media only screen and (max-width: 600px) {
  .one1 {
    margin-top: 0px;
  }

  .heading1 {
    text-align: center;
  }

  .trns {
    transform: translateX(0px);
  }
  
}