.img {
    width: 300px;
}

.img2 {
    width: 250px;
    margin-top: 20px;
}

.img3 {
    width: 250px;
}

@media only screen and (max-width:600px) {
    .img2 {
        margin-top: 0px;
    }

    .lside {
        transform: translateX(50px);
    }
    .img2 {
        margin-top: 30px;
    }
}
